.items-table {
   tbody {
      tr {
         padding: 0;
         border-radius: $radius;
         position: relative;
         box-shadow: 0 1px 0 0 #f8f8f8;
         @include transitionMedium;
         .dropdown {
            opacity: 0;
         }
         &.active,
         &:hover {
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
            z-index: 1;
            .dropdown {
               opacity: 1;
            }
         }
         th,
         td {
            border: 0;
            padding: 18px 8px;
         }
      }
   }
   @include respond("phone") {
      width: 500px;
   }
}

.star-review {
   i {
      font-size: 16px;
      &.text-gray {
         color: #d9d9d9;
      }
   }
   span {
      color: $black;
   }
}

// Form Head
.form-head {
   .breadcrumb {
      background: transparent;
      padding: 0;
   }
   .search-area {
      max-width: 300px;
      border-radius: 0;
      @include respond("laptop") {
         max-width: 250px;
      }
      @include respond("phone") {
         max-width: 250px;
      }
      .form-control {
         border: 0;
         height: 56px;
         padding: 10px 15px;
         font-size: 16px;
         background: #f1f1f1;
         border-radius: 0;
         @include respond("laptop") {
            height: 41px;
            padding: 10px 15px;
            font-size: 13px;
         }
      }
      .input-group-append .input-group-text {
         background: #f1f1f1;
         padding: 0 5px 0 30px;
         @include respond("laptop") {
            padding: 0 5px 0 30px;
         }
         i {
            font-size: 20px;
            color: $body-color;
            @include respond("laptop") {
               font-size: 18px;
            }
         }
      }
   }
   .dropdown {
      .btn {
         i {
            transform: scale(1.3);
            -moz-transform: scale(1.3);
            -webkit-transform: scale(1.3);
            -ms-transform: scale(1.3);
            -o-transform: scale(1.3);
            display: inline-block;
         }
      }
      &:after {
         color: $primary;
         font-size: 25px;
      }
   }
   /* .btn i{
		line-height: 1;
		transform: scale(1.3);
		display: inline-block;
		margin-right: 5px;
	} */
   /* .btn-outline-primary{
		border-color:#eee;
		&:hover{
			border-color:$primary;
		}
	} */
   & > * {
      @include respond("phone-land") {
         margin-right: 5px !important;
         margin-bottom: 10px;
         vertical-align: baseline;
         margin-left: 0 !important;
      }
   }
   @include respond("phone-land") {
      display: block !important;
   }
}
.rounded {
   border-radius: $radius !important;
}
.btn-link {
   font-weight: 500;
   font-size: 16px;
   @include respond("laptop") {
      font-size: 14px;
   }
}
.deznav .copyright {
   font-size: 14px;
   padding: 0 45px 60px;
   margin-top: 40px;
   color: #969ba0;
   margin-bottom: 40px;

   p {
      margin-bottom: 10px;
   }
   strong {
      display: block;
   }

   @include respond("laptop") {
      padding: 0 25px 60px;
   }
   @include respond("phone-land") {
      padding-bottom: 60px;
   }
}
.widget-buyer {
   .media {
      img {
         width: 30px;
      }
      .media-body {
         h5 {
            font-size: 14px;
            margin-bottom: 0px;
         }
         p {
            font-size: 13px;
         }
      }
   }
}

.recentOrderTable {
   table {
      margin-bottom: 0;
      overflow: hidden;
      thead {
         th {
            font-size: 12px;
         }
      }
      tbody {
         td {
            color: $dark;
            font-weight: 500;
            line-height: 40px;
            @at-root [data-theme-version="dark"] & {
               color: $d-ctl;
            }
            img {
               border-radius: 50px;
            }
            .custom-dropdown {
               i {
                  padding: 0 10px;
                  cursor: pointer;
               }
            }
         }
      }
   }
}
.activity_overview {
   .nav-tabs {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
   }
   .custom-tab-1 .nav-link {
      color: rgba(255, 255, 255, 0.5);
      padding-left: 0;
      padding-right: 0;
      margin-right: 30px;

      &:hover,
      &.active {
         background: transparent;
         color: #fff;
         border-color: #fff;
      }
   }
}
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
   background-color: transparent !important;
   opacity: 0.9;
}

.chart-link {
   @include respond("phone") {
      display: flex;
      align-items: center;
   }
   @include respond("tab-land") {
      display: flex;
      align-items: center;
   }
   @include respond("desktop") {
      display: flex;
      align-items: center;
   }
   .week-link {
      display: inline-block;
      // @include respond('tab-land'){
      //     display: flex;
      // }
   }
   a {
      display: inline-block;
      font-size: 12px;
      font-weight: 500;
      margin-right: 10px;
      i {
         font-size: 13px;
         &.text-primary {
            color: $primary;
         }
         &.text-muted {
            color: #dae2f3 !important;
         }
      }
   }
   .form-control {
      max-width: 97px;
      display: inline-block;
   }
}
.input-group {
   &.input-group-lg {
      .form-control,
      .input-group-text {
         height: 80px;
         padding: 10px 30px;
         @include respond("phone") {
            height: 50px;
            padding: 10px 15px;
            font-size: 14px;
         }
      }
      .input-group-text {
         width: 200px;
         text-align: left;
         @include respond("phone") {
            width: 120px;
            padding: 10px 15px;
            height: 50px;
            font-size: 14px;
         }
      }
   }
}

//revenue-tabs
.revenue-tabs {
   .nav-tabs {
      border: 0;
      .nav-item {
         margin: 0px 5px;
      }
      .nav-link {
         border-radius: $radius;
         padding: 10px 20px;
         border: 0px solid $primary;
         color: #8dac9a;
         font-size: 14px;
         background: transparent;
         &.active {
            color: $white;
            background: $primary;
         }
         @include respond("phone") {
            padding: 7px 12px;
         }
      }
   }
}

//event-tabs
.event-tabs {
   .nav-tabs {
      border: 0;
      .nav-link {
         padding: 15px 30px;
         margin: 0px 5px;
         border: 1px solid $primary;
         border-radius: $radius;
         color: $primary;
         &.active {
            background: $primary;
            color: $white;
         }
         @include respond("laptop") {
            padding: 0.625rem 1rem;
            font-size: 0.813rem;
         }
         @include respond("phone") {
            margin: 0px 3px;
            padding: 10px 14px;
            font-size: 0.713rem;
         }
      }
   }
}

.default-select {
   &.style-1 {
      width: auto !important;
      height: auto;
      .btn {
         font-size: 12px;
         padding: 5px;
         border: 0 !important;
         &:after {
            margin-left: 0.5em;
            font-size: 16px;
         }
      }
      .dropdown-menu {
         z-index: 1;
         .dropdown-item {
            font-size: 12px;
         }
      }
   }
   &.style-2 {
      .btn {
         padding: 15px 30px;
         &:after {
            color: $primary;
            margin-left: 8px;
            font-size: 20px;
         }
      }
      @include respond("laptop") {
         .btn {
            padding: 0.625rem 1rem;
            font-size: 0.813rem;
         }
      }
   }
}

.card-tabs {
   .nav-tabs {
      border-bottom: 0px;
      background: transparent;
      padding: 5px;
      border-radius: 0;
      flex-wrap: unset;

      .nav-link {
         border-radius: 0;
         background: transparent;
         padding: 10px 14px;
         font-weight: 500;
         border-bottom: 1px solid $border-color !important;
         font-size: 14px;
         border: 0;
         color: $black;
         position: relative;
         &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 0px;
            width: 100%;
            background: $primary;
            content: "";
         }
         &.active {
            background: transparent;
            color: $primary;
            &:after {
               height: 2px;
            }
         }
         @include respond("laptop") {
            padding: 8px 15px;
            font-weight: 400;
            font-size: 14px;
         }
      }
      &.tabs-lg {
         .nav-link {
            padding: 23px 30px;
            font-size: 16px;
            @include respond("phone-land") {
               padding: 10px 15px;
               font-size: 14px;
            }
         }
      }
      @include respond("phone-land") {
         flex-wrap: wrap;
      }
   }
   &.icontabs {
      .nav-tabs {
         background: transparent;
         .nav-item {
            .nav-link {
               height: 42px;
               width: 42px;
               line-height: 42px;
               text-align: center;
               background: rgba($gray, 0.6);
               color: $white;
               margin-right: 15px;
               border-radius: 50px;
               padding: 0;
               &.active {
                  background: $warning;
               }
               i {
                  font-size: 25px;
                  line-height: 42px;
               }
            }
         }
      }
   }
   &.style-1 {
      .nav-tabs {
         background: transparent;
         .nav-link {
            font-size: 16px;
            color: $black;
            position: relative;
            &:after {
               content: none;
            }
            span {
               position: absolute;
               bottom: -4px;
               left: 50%;
               transform: translatex(-50%);
               content: "";
               height: 4px;
               width: 0;
               @include transitionMedium;
            }
            &.active {
               span {
                  width: 100%;
               }
            }
         }
      }
   }
}

.revenue-chart
   .apexcharts-legend.apexcharts-align-left
   .apexcharts-legend-series {
   display: flex !important;
   margin-right: 30px !important;
   margin-bottom: 30px !important;
}
.revenue-chart-bar {
   margin-bottom: -30px;

   @include respond("phone") {
      margin-bottom: 20px;
      float: none;
      padding-left: 20px;
   }
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
   .revenue-chart-bar {
      margin-bottom: 20px;
      float: none;
      padding-left: 20px;
   }
}
#activeUser {
   height: 215px !important;
}
#activeUser {
   height: 180px !important;
}
span#counter {
   font-size: 30px;
   font-weight: 700;
   color: $primary;
}
.social-graph-wrapper {
   text-align: center;
   padding: 20px;
   position: relative;
   color: $white;
   border-top-left-radius: 0.25rem;
   border-top-right-radius: 0.25rem;

   &.widget-facebook {
      background-color: $facebook;
   }
   &.widget-twitter {
      background-color: $twitter;
   }
   &.widget-linkedin {
      background-color: $linkedin;
   }
   &.widget-googleplus {
      background-color: $google-plus;
   }
   .s-icon {
      font-size: 24px;
      position: relative;
      // top: 0.625rem;
      padding: 0 10px;
   }
}
.recent-stats i {
   font-size: 11px;
   position: relative;
   top: -1px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
   #lineChart_3Kk {
      height: 140px !important;
   }
}
/* add menu sidebar */
.add-menu-sidebar {
   background: $primary;
   color: $white;
   padding: 20px 18px;
   display: block;
   font-size: 18px;
   text-align: center;
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
   border-radius: $radius;
   position: relative;
   overflow: hidden;
   margin: 25px 50px;
   &:hover {
      color: $white;
   }
   @include respond("laptop") {
      margin: 20px 25px 20px;
   }
}

.donut-chart-sale {
   position: relative;
   small {
      font-size: 16px;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      display: flex;
      align-items: center;
      top: 0;
      justify-content: center;
      font-weight: 600;
   }
}
.profile-bx {
   .profile-image {
     // background-image: url("../../images/circle.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      img {
         margin: 18px;
         width: 118px;
      }
   }
}

//search-area
.search-area {
   width: 300px;
   .form-control {
      background: rgba($body-bg, 0.8) !important;
      border-width: 1px;
      padding-left: 20px;
      min-height: 57px;
      border-radius: 0;
      border-right: 0;
      border-top-left-radius: $radius;
      border-bottom-left-radius: $radius;
      &::placeholder {
         color: #757575;
      }
      &:focus {
         border-color: $border-color;
      }
   }
   .input-group-text {
      background: rgba($body-bg, 0.8);
      border: 1px solid $border-color;
      border-top-right-radius: $radius !important;
      border-bottom-right-radius: $radius !important;
      padding-left: 0;
      padding-right: 20px;
      border-left: 0;
      i {
         font-size: 25px;
      }
   }
   @include respond("mid-desktop") {
      width: 230px;
   }
   @include respond("laptop") {
      .form-control {
         min-height: 45px;
      }
      .input-group-text i {
         font-size: 18px;
      }
   }
   @include respond("tab-port") {
      width: 200px;
   }
}

//donut chart
.donut-chart-sale {
   z-index: 1;
   .circle {
      height: 72px;
      width: 72px;
      z-index: -1;
      content: "";
      position: absolute;
      border-radius: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
   }
   small {
      font-size: 14px;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      display: flex;
      align-items: center;
      top: 0;
      justify-content: center;
      font-weight: 600;
   }
}
.donut-chart-sale2 small {
   font-size: 16px;
   position: absolute;
   width: 65%;
   height: 65%;
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
   left: 50%;
   border-radius: 76px;
   display: flex;
   align-items: center;
   top: 50%;
   transform: translate(-50%, -50%);
   justify-content: center;
   font-weight: 600;
}
.min-w24 {
   min-width: 24px;
}
.min-w30 {
   min-width: 30px;
}
.min-w32 {
   min-width: 32px;
}
.min-w42 {
   min-width: 42px;
}
.min-w46 {
   min-width: 46px;
}
.min-w50 {
   min-width: 50px;
}
.lineChart {
   max-width: 60%;
   max-height: 85px;
   margin-left: auto;
}
.line-chart-demo {
}

.form-group.style-1 {
   .form-control {
      border-color: transparent;
      margin-right: -45px;
      z-index: 0;
      color: $black;
      background: #f0f0f0;
      border-radius: $radius;
   }
}

.previous-transactions {
   tbody {
      tr {
         td:first-child {
            width: 100px;
         }
      }
   }
}

//star rating
.rating-widget .rating-stars ul li i {
   font-size: 25px !important;
}

.heart {
   width: 60px;
   height: 60px;
   display: inline-block;
   background: url("../../images/like.png");
   cursor: pointer;
   margin: -25px -15px;
}
.heart-blast {
   background-position: -1680px 0;
   transition: background 1s steps(28);
}

//card-footer style-1
.card-footer.style-1 {
   position: relative;
   a {
      position: absolute;
      height: 68px;
      width: 68px;
      line-height: 68px;
      font-size: 25px;
      left: 50%;
      border-radius: 100%;
      background: $white;
      color: $primary;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      transform: translateX(-50%);
      bottom: -10px;
   }
}

.lineChart {
   max-width: 50%;
   margin-left: auto;
   max-height: 85px;
}

//event-bx
.event-bx {
   .image-bx {
      border-radius: $radius;
      overflow: hidden;
      border: 1px solid $border-color;
      img {
         width: 100%;
      }
      .info {
         padding: 20px 20px;
         ul {
            display: flex;
            flex-flow: wrap;
            li {
               font-size: 14px;
               color: $black;
               font-weight: 500;
               display: inline-block;
               width: 50%;
               float: left;
               margin-bottom: 8px;
               i {
                  color: $primary;
                  font-size: 20px;
                  margin-right: 10px;
               }
            }
         }
      }
   }
   .owl-dots {
      display: flex;
      position: absolute;
      top: -54px;
      right: 100px;
      .owl-dot {
         height: 10px;
         width: 10px;
         border-radius: 10px;
         background: #dedede;
         margin: 0 5px;
         &.active {
            background: $primary;
         }
      }
      @include respond("phone") {
         top: -46px;
         right: auto;
         left: 46px;
      }
   }
   .owl-nav {
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 192px;
      top: -65px;
      right: 52px;
      .owl-prev,
      .owl-next {
         height: 34px;
         width: 34px;
         line-height: 32px;
         cursor: pointer;
         text-align: center;
         font-size: 20px;
         border-radius: 50px;
         border: 1px solid $primary;
         color: $primary;
         &:hover {
            background: $primary;
            color: $white;
         }
      }
      @include respond("phone") {
         top: -57px;
         right: auto;
         left: 0;
      }
   }
}

.image-placeholder {
    position: relative;
    max-width: 130px;
    margin: 10px auto;
    .avatar-edit {
        position: absolute;
        right: -10px;
        z-index: 1;
        top: 0px;
        input {
            display: none;
            + label {
                display: inline-block;
                width: 34px;
                height: 34px;
                margin-bottom: 0;
                border-radius: 100%;
                background: #FFFFFF;
                border: 1px solid transparent;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                cursor: pointer;
                font-weight: normal;
                transition: all .2s ease-in-out;
                &:hover {
                    background: #f1f1f1;
                    border-color: #d6d6d6;
                }
                &:after {
                    content: "\f040";
                    font-family: 'FontAwesome';
                    color: #757575;
                    position: absolute;
                    top:6px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: auto;
                }
            }
        }
    }
    .avatar-preview {
        width: 130px;
        height: 95px;
        position: relative;
        border-radius: 30px;
        border: 3px solid #F8F8F8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        & > div {
            width: 100%;
			height: 100%;
            border-radius: 30px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
			overflow: hidden;
			img{
				height: 100%;
				width: 100%;
				object-fit: cover
			}
        }
    }
}
.project-boxed {
    overflow: hidden;
}
