// .authincation{
//     height: 100%;

// }

.authincation-content{
    background: $white;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    border-radius: 5px;
    @at-root [data-theme-version="dark"] & {
        background: $dark-card;
        box-shadow: none;
    }

}

.welcome-content{
    background: url('../../images/1.jpg');
    background-size: cover;
    background-position: center;
    height: 100%;
    padding: 75px 50px;
    position: relative;
    z-index: 1;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    &::after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: $primary;
        opacity: 0.75;
        z-index: -1;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    .welcome-title{
        color: $white;
        font-weight: 500;
        font-size: 20px;
    }
    p{
        color: $white;
    }

    .brand-logo{
        a{
            display: inline-block;
            margin-bottom: 20px;
            font-weight: 700;
            color: $white;
            font-size: 20px;
        img{
            width: 100px;
        }
        }
    }

    .intro-social{
        position: absolute;
        bottom: 75px;
        ul{
            margin-bottom: 0;
            li{
                display: inline-block;
                a{
                    color: $white;
                    font-size: 14px;
                    padding: 0px 7px;
                }
            }
        }
    }
}

.auth-form{
    padding: 50px 50px;
	@include respond('phone') {
		padding: 30px;
	}	
    .btn{
        height: 50px;
        font-weight: 700;
    }
    .page-back{
        display: inline-block;
        margin-bottom: 15px;
    }
}


//login-wrapper
.login-wrapper{
	display:flex;
	min-height:100vh;
	.login-aside-left{
		width:580px;
		position:relative;
		background-size: cover;
		padding: 50px 50px;
		display:table;
		color:#fff;
		z-index:1;
		&:after{
			position:absolute;
			top:0;
			left:0;
			height:100%;
			width:100%;
			z-index:-1;
			content:"";
			background: linear-gradient(90deg, $secondary 0%, $primary 100%);
		}
		.login-description{
			display:table-cell;
			vertical-align:bottom;
			padding-bottom:40px;
		}
	}
	.login-aside-right{
		flex: 1;
	}
	.social-icons{	
		display:flex;
		li{
			margin-right:10px;
			a{
				height:40px;
				width:40px;
				color:#fff;
				border-radius:40px;
				line-height:40px;
				font-size: 18px;
				display: inline-block;
				text-align:center;
				@include transitionMedium;
				border:1px solid #fff;
				&:hover{
					background:#fff;
					color:#000;
				}
			}
		}
	}
	
	.login-logo{
		position:absolute;
	}
	.authincation-content{
		background-color:transparent;
		box-shadow:none;
	}
	.form-control,
	.btn{
		border-radius: 8px;
	}
	@include respond ('tab-land'){
		.login-aside-left{
			width:470px;
		}
	}
	@include respond ('tab-port'){
		display:block;
		.login-logo{
			position:relative;
			margin-bottom: 20px;
			display: block;
		}
		.social-icons {
			justify-content: center;
			li{
				margin-left:5px;
				margin-right:5px;
			}
		}
		.login-aside-left{
			text-align: center;
			width: 100%;
			display:block;
		}
	}
	@include respond ('phone'){
		.login-aside-left{
			padding:50px 30px;
			.login-description{
				padding-bottom:0;
			}
		}
		h2, .h2{
			font-size:1.5rem;
		}
		h4, .h4 {
			font-size: 0.8rem;
		}
		
	}
}