.avatar-sm {
  object-fit: cover;
  height: 30px;
  width: 30px;
  display: inline;
}

.avatar-md {
  object-fit: cover;
  height: 42px;
  width: 42px;
  display: inline;
}